<template>
  <div class="account">
    <div class="container">
      <div class="account-tabs primary-title">
        <router-link
          class="tab-item"
          :to="$i18nRoute({ name: 'AccountInfo' })"
          :class="{ active: route.path == '/account/info' }"
        >
          <span>{{ $t("personPage.title") }}</span>
        </router-link>
        <router-link
          class="tab-item"
          :class="{ active: route.path == '/account' }"
          :to="$i18nRoute({ name: 'AccountAnketa' })"
        >
          <span>{{ $t("personPage.anketa") }}</span>
        </router-link>
        <div class="tab-item" @click="logout">
          <span>{{ $t("personPage.logout") }}</span>
        </div>
      </div>
      <router-view v-slot="{ Component, route }">
        <transition mode="out-in" name="slide-fade">
          <div :key="route.name">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useLanguage } from "@/utils/language";

const store = useStore();
const router = useRouter();
const route = useRoute();

const { i18nRoute } = useLanguage();

const logout = () => {
  store.dispatch("auth/dropToken", {
    cb: () => {
      router.push(
        i18nRoute({
          name: "Home",
        })
      );
    },
  });
};
</script>

<style lang="scss" scoped>
.account {
  padding: 3.125rem 0;

  .success-message {
    position: fixed;
    top: 1.25rem;
    right: 1.25rem;
    font-size: 1.25rem;
    color: var(--white);
    z-index: 10;
    background: var(--success-color);
    border-radius: 0.63rem;
    padding: 1.25rem 1.88rem;
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5.25rem;

    .tab-item {
      color: var(--disabled-color);
      margin: 0 1.25rem;
      padding-bottom: 0.125rem;
      border-bottom: 0.25rem solid transparent;
      cursor: pointer;

      &.active {
        color: var(--dark-blue);
        border-color: var(--primary-blue);
      }
    }
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3.75rem;
    grid-row-gap: 1.875rem;

    .change-button {
      grid-column: 2 / 3;
    }

    .form-input {
      label {
        font-weight: 400;
        font-size: 1.25em;
        color: var(--text-gray);
        line-height: 180%;
        margin-bottom: 0.3125rem;
      }
    }

    .form-img {
      display: flex;
      grid-column: 2 / 3;
      grid-row: 4 / 6;

      .img-item {
        &:first-child {
          margin-right: 2.9375rem;
        }

        span {
          font-weight: 400;
          font-size: 1.25em;
          line-height: 180%;
          color: var(--text-gray);
        }

        .img-wrap {
          border: 0.125rem solid #bec1ce;
          border-radius: 0.625rem;
          overflow: hidden;
          width: 12.1875rem;
          height: 13.1875rem;
          margin-bottom: 0.63rem;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .account {
    padding: 2.125rem 0;

    &-tabs {
      margin-bottom: 3.44rem;
    }

    &-form {
      font-size: 11.5px;
      .form-img {
        .img-item {
          &:first-child {
            margin-right: 1.4375rem;
          }
          .img-wrap {
            width: 9.375rem;
            height: 10.1875rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .account {
    &-tabs {
      width: 100%;
      font-size: 1.125rem;
      justify-content: space-between;
      align-items: center;

      .tab-item {
        margin: 0 0.3125rem;
        text-align: center;
      }
    }

    &-form {
      grid-template-columns: 100%;
      max-width: 20rem;
      margin: 0 auto;

      .change-button {
        grid-column: auto;
      }

      .form-img {
        grid-column: auto;
        grid-row: auto;

        .img-item {
          .img-wrap {
            width: 100%;
            height: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
